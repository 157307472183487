import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink  } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { upload } from '@testing-library/user-event/dist/upload';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';


// const httpLink = createHttpLink({
//     // uri: "https://dev.resin.getitw.com/graphql",
// //  uri: "https://qa.resin.getitw.com:3000/graphql",
//   uri: 'http://localhost:3000/graphql',
// });

// const token = localStorage.getItem('token');
// console.log("token from localStorage", token);

const uploadLink = createUploadLink({
  uri: "https://dev.resin.getitw.com/graphql",
//  uri: "https://qa.resin.getitw.com:3000/graphql",
// uri: 'http://localhost:3000/graphql',
credentials: 'include',
});


const authLink = setContext((_, { headers }) => {
  // const token = localStorage.getItem('token');  
  return {
    headers: {
      ...headers,
      // authorization: token ? `Bearer ${token}` : "",  
    }
  };
});

const link = ApolloLink.from([authLink, uploadLink]);

// Create the Apollo Client
const client = new ApolloClient({
  link:uploadLink,
  cache: new InMemoryCache(),
});

export default client;
