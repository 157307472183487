import {Link} from 'react-router-dom';

import itwLogo from '../../assets/logo.svg';

const LogoMobile = () => {
    return (

            <Link to="/dashboard">
                <img src={itwLogo} alt="Logo" width={55}/>
            </Link>
    );
};

export default LogoMobile;