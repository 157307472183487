import { gql } from '@apollo/client';

const GET_SAMBA_BY_CRITERION_ID = gql`
  query GetSambaByCriterionId($criterionId: ID!) {
    getSambaByCriterionId(criterionId: $criterionId) {
      _id
      sambaPersonId
      mvrScore
      mvrState
      mvrDate
      correctiveAction
    }
  }
`;

export { GET_SAMBA_BY_CRITERION_ID };
