import { useNavigate } from 'react-router-dom';
import Header from '../progressDots';
import './personalVehicle.css'; 
import { useUploadContext } from '../../../context/uploadProvider';


function PersonalVehicle(): JSX.Element {
  const navigate = useNavigate();

  const {setUploadType} = useUploadContext();

  const handleYes = () => {
    setUploadType('insuranceCard');
    navigate('/mobileDriverOnboard/upload');
  }
  const handleNo = () => {
    navigate('/mobileDriverOnboard/confirmation');
  }
  return (
    <div className='mobile-main vehicle-setup'>
        <Header />
      <div className="personal-vehicle-body">
        {/* <h2 className="personal-vehicle-h2">Vehicle</h2> */}
      
      <div className="body question">
        Will you drive your personal vehicle for Trulieve?
      </div>
      <div className="personal-vehicle-button-container">
        <button className="personal-vehicle-button-no" onClick={handleNo}>NO</button>
        <button className="personal-vehicle-button-yes" onClick={handleYes}>YES</button>
      </div>
      </div>
    </div>
  );
}

export default PersonalVehicle;
