import React from 'react';
import './pageBackButton.css'; 

interface PageBackButtonProps {
  name:String;
  onClick: () => void;
}

const PageBackButton = ({ onClick, name }: PageBackButtonProps) => {
  return (
    <button className="button-style" onClick={onClick}>
      {"< " + name }
    </button>
  );
};

export default PageBackButton;

