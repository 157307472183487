import React from 'react';
import {Link} from 'react-router-dom';
import './itwIcon.css';
import itwLogo from '../../assets/itw_icon_white_circle_no_eyes_black_goat_green_grass.svg';

const ItwLogo = () => {
    return (
        <div className="logo-container">
            <Link to="/dashboard">
                <img src={itwLogo} alt="Logo"/>
            </Link>
        </div>
    );
};

export default ItwLogo;

//
// import React from 'react';
// import {Link} from 'react-router-dom';
// import './itwIcon.css';
// import itwLogo from '../../assets/logo.svg';
//
// const ItwLogo = () => {
//     return (
//         <div className="logo-container">
//             <Link to="/dashboard">
//                 <img src={itwLogo} alt="Logo" />
//             </Link>
//         </div>
//     );
// };
//
// export default ItwLogo;
