import React from 'react';
import { useNavigate } from 'react-router-dom';

import MainTemplate from '../components/mainTemplate';
import StatsComponent from '../components/statsDashboard/index';
import PageTitle from '../components/pageTitle';
import ChartComponent from '../components/chart/index'; 
import EmployeesIcon from '../assets/employees-list.svg';

import '../components/statsDashboard/statsDashboard.css';

import { DocumentChartBarIcon, ListBulletIcon } from '@heroicons/react/24/outline';
import '../css/App.css';

interface PieChartDataPoint {
  name: string;
  y: number;
}

interface Stat {
    name: string;
    stat: string;
    previousStat: string;
    change: string;
    changeType: 'increase' | 'decrease';
  }
  
  const stats: Stat[] = [
    { name: 'Drivers', stat: '58', previousStat: '70,946', change: '12%', changeType: 'increase' },
    { name: 'States', stat: '11', previousStat: '56.14%', change: '2.02%', changeType: 'increase' },
    { name: 'Drivers With Open Tasks', stat: '21', previousStat: '28.62%', change: '4.05%', changeType: 'decrease' },
  ];

const DriverAnalytics: React.FC = () => {
  const chartTitle = 'DRIVERS BY TYPE';
  const chartCategories = ['Cultivator', 'Processor', 'Retail'];
  const chartData = [44, 8, 88];
  const yAxisValue = ' Location Count';
  const chartTitle1 = 'LOCATIONS BY CLAIMS';
  const chartCategories1 = ['Cultivator', 'Processor', 'Retail'];
  const chartData1 = [15, 25, 49];
  const yAxisValue1 = 'USD';
  const chartTitleLine = 'REVENUE (5yr)';
  const chartCategoriesLine = ['2018', '2019', '2020', '2021', '2022'];
  const chartDataLine = [2900000, 3800000, 3900000, 4800000, 5900000];
  const yAxisDataLine = 'USD';
  const chartTitlePie = 'DRIVERS BY STATE';
  const chartCategoriesPie = ['MI', 'TN', 'CO', 'FL', 'NC', 'SC', 'GA', 'LA'];
  const chartDataPieValues = [17, 28, 48, 3, 12, 32, 9, 21];
  const chartDataPie: PieChartDataPoint[] = chartCategoriesPie.map((name, index) => ({
    name: name,
    y: chartDataPieValues[index]
  }));

  const navigate = useNavigate();

  const [isListHovered, setIsListHovered] = React.useState(false);
  const [isDashHovered, setIsDashHovered] = React.useState(false);
  
  const handleListIconMouseEnter = () => {
    setIsListHovered(true); 
  };

  const handleListIconMouseLeave = () => {
    setIsListHovered(false); 
  };

  const handleDashIconMouseEnter = () => {
    setIsDashHovered(true); 
  };

  const handleDashIconMouseLeave = () => {
    setIsDashHovered(false); 
  };

  return (
    <div className="">
      <MainTemplate />
      <div className="main-container">
          <div className="header">
              <div className="title-icons-container">
                  <div className="logo-container locations-icon">
                      <img src={EmployeesIcon} alt="Logo" />
                  </div>
                  <PageTitle pageTitle="Drivers" />
                  <div className='map-icon-location' style={{marginTop:'2.25vh', marginLeft:'1vw'}}>
                      <div
                      onMouseEnter={handleDashIconMouseEnter}
                      onMouseLeave={handleDashIconMouseLeave}
                      // style={{marginLeft:'2vw'}}
                      >
                      {isDashHovered ? (
                          <DocumentChartBarIcon className="cursor-pointer h-6 w-6 text-gray-400 pomegranate-glow" />
                      ) : (
                          <DocumentChartBarIcon className="cursor-pointer h-6 w-6 text-gray-400 pomegranate-glow" 
                          style={{color: '#e32661'}}
                          />
                      )}
                      </div>
                      <div
                      onMouseEnter={handleListIconMouseEnter} 
                      onMouseLeave={handleListIconMouseLeave} 
                      onClick={() => navigate('/employeePage')}
                      >
                      {isListHovered ? (
                          <ListBulletIcon className="cursor-pointer h-6 w-6 text-gray-500" />
                      ) : (
                          <ListBulletIcon className="cursor-pointer h-6 w-6 text-gray-400 " />
                      )}
                      </div>
                  </div>    
              </div>
          </div>
          <div className="flex-1 mb-4 overflow-auto">
            <StatsComponent 
                stats={stats} onOverdueTasksClick={()=>console.log('Overdue tasks clicked')} onWeekTasksClick={()=>console.log('Week tasks clicked')} onMonthTasksClick={()=>console.log('Month tasks clicked')}
            />
            <div className="flex justify-center">
              <div 
                className="grid grid-cols-2"
                style = {{gap: '6rem'}}
              >
                <div style={{ width: '100%', height: 'auto' }}>
                  <ChartComponent
                    title={chartTitle1}
                    categories={chartCategories1}
                    data={chartData1}
                    type='column'
                    colors={['#E32661']}
                    yAxisValue='Number of Claims'
                  />
                </div>
                <div style={{ width: '100%', height: 'auto'}}>
                  <ChartComponent
                    title={chartTitleLine}
                    categories={chartCategoriesLine}
                    data={chartDataLine}
                    type='line'
                    colors={['#007E9D']}
                    yAxisValue='USD'
                  />
                </div>
                <div style={{ width: '100%', height: 'auto' }}>
                  <ChartComponent
                    title={chartTitlePie}
                    data={chartDataPie}
                    type='pie'
                    colors={['#007E9D', '#E32661', '#FFBF59', '#3B5B80', '#F4F0E8', '#FFB417', '#555554']}
                  />
                </div>
                <div style={{ width: '100%', height: 'auto' }}>
                  <ChartComponent
                    title={chartTitle}
                    categories={chartCategories}
                    data={chartData}
                    type='column'
                    colors={['#ffbf59']}
                    yAxisValue='Driver Count'
                  />
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
};

export default DriverAnalytics;
