import React, { createContext, useContext, useState, ReactNode } from 'react';

type UploadContextType = {
  uploadType: string;
  setUploadType: (type: string) => void;
  captureSide: string;
  setCaptureSide: (side: string) => void;
  nextCaptureSide: string;
  setNextCaptureSide: (side: string) => void;
  sidesCapturedStatus: { front: boolean; back: boolean };
  setSidesCapturedStatus: (status: { front: boolean; back: boolean }) => void;
  extractedData: any;
  setExtractedData: (data: any) => void;
  extractedVin: any;
  setExtractedVin: (vin: any) => void;
};

const UploadContext = createContext<UploadContextType | undefined>(undefined);

export const useUploadContext = (): UploadContextType => {
  const context = useContext(UploadContext);
  if (!context) {
    throw new Error('useUploadContext must be used within an UploadProvider');
  }
  return context;
};

export const UploadProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [uploadType, setUploadType] = useState<string>('driverLicense');
  const [captureSide, setCaptureSide] = useState<string>('front');
  const [nextCaptureSide, setNextCaptureSide] = useState<string>('back');
  const [sidesCapturedStatus, setSidesCapturedStatus] = useState<{ front: boolean; back: boolean }>({ front: false, back: false });
  const [extractedData, setExtractedData] = useState<any>(null);
  const [extractedVin, setExtractedVin] = useState<any>(null);

  console.log('extractedVin from context', extractedVin);
  return (
    <UploadContext.Provider value={{ uploadType, setUploadType, captureSide, setCaptureSide, nextCaptureSide, setNextCaptureSide, sidesCapturedStatus, setSidesCapturedStatus, extractedData, setExtractedData, extractedVin, setExtractedVin }}>
      {children}
    </UploadContext.Provider>
  );
};
