import { Disclosure } from '@headlessui/react'
import Table from '../table';
import Chart from '../chart';
import { FC } from 'react';
import {
    ChevronDownIcon,
    ChevronUpIcon,
  } from '@heroicons/react/20/solid';
import { useState } from 'react';

  
const ProgramUpcoming: FC =() => {
    const [isExpanded, setIsExpanded] = useState(false);

      const toggleExpand = () => {
        setIsExpanded(!isExpanded);
      };


      const columnDefs = [
        { field: 'DRIVER', flex: 1, cellClass: 'underline-location' },
        { field: 'ACTION NEEDED', flex: 1 },
        { field: 'STATUS', flex: 1 },
        { field: 'DUE DATE', flex: 1 },
    ];

    const rowData = [
      {
          '_id': '1',
          'DRIVER': 'Patrick Almquist',
          'ACTION NEEDED': 'Driver\'s License Expiration',
          'STATUS': 'Needs Update',
          'DUE DATE': 'July 1, 2024',
      },
      {
          '_id': '2',
          'DRIVER': 'Branden Albenez',
          'ACTION NEEDED': 'Onboarding Profile',
          'STATUS': 'Needs Update',
          'DUE DATE': 'July 13, 2024',
      },
      {
          '_id': '3',
          'DRIVER': 'Garett Babb',
          'ACTION NEEDED': 'Samba Enrollment',
          'STATUS': 'Fit For Duty',
          'DUE DATE': 'July 19, 2024',
      },
      {
          '_id': '4',
          'DRIVER': 'Jonathan Baez',
          'ACTION NEEDED': 'Samsara Enrollment',
          'STATUS': 'Fit For Duty',
          'DUE DATE': 'July 30, 2024',
      }
      // Add more entries as needed
  ];
  


    return (
        <>
        <div className="flex items-center mt-3">
        <h2 className="text-xl font-semibold">Upcoming</h2>
        <button onClick={toggleExpand} className="text-gray-500 hover:text-gray-700">
          {isExpanded ? (
            <ChevronUpIcon className="h-8 w-8" aria-hidden="true" />
          ) : (
            <ChevronDownIcon className="h-8 w-8" aria-hidden="true" />
          )}
        </button>
      </div>
      {isExpanded && (
        <div className={"display-flex space-between w-full mt-4 mb-4"}>
            <Table columnDefs={columnDefs} rowData={rowData} onRowClick={()=>{}}/>
            <div className={"mt-6"}>
              <Chart 
                yAxisValue={"Action Count"} 
                title={'Upcoming Actions By Month'} 
                categories={['January','February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']} 
                data={[3, 20, 13, 9, 18, 22, 8, 2, 6, 15, 10, 5]} 
                type={'column'} 
                colors={['#adebd8']}
              />
            </div>
            
        </div>
    )}
        </>
    );
  }

  export default ProgramUpcoming;
  