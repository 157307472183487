import React from 'react';
import { Navigate } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import CHECK_SESSION from '../../queries/checkSession';

interface ProtectedRouteProps {
    children: React.ReactNode;
    allowedRoles: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, allowedRoles }) => {
    const { data, loading, error } = useQuery(CHECK_SESSION, {
        fetchPolicy: 'network-only', // Ensure the request is always sent to the server
    });
console.log("data", data)
    if (loading) {
        return <div>Loading...</div>;
    }

    if (error || !data?.checkSession.isAuthenticated) {
        return <Navigate to="/" replace />;
    }

    // Check if the user's role is in the allowedRoles array
    // if (!allowedRoles.includes(data.checkSession.role)) {
    //     return <Navigate to="/unauthorized" replace />; // Redirect to unauthorized page if role is not allowed
    // }

    return <>{children}</>;
};

export default ProtectedRoute;
