import React from 'react'; 
import './horizontalNavBar.css';

export type NavBarItem={
    label:string; 
    href:string; 
    onClick?: (event: React.MouseEvent) => void;
};

type NavBarProps={
    items: NavBarItem[];
};

const HorizontalNavBar = ({items} : NavBarProps) => {
    return (
        <div>
            <ul className="h-navbar-container-1">
                {items.map((item, index) => (
                    <div key={index} onClick={item.onClick} style={{ cursor: 'pointer' }}>
                        <li className="mr-6">
                            <a className="text-blue-500 hover:text-blue-800" href={item.href}>{item.label}</a>
                        </li>
                    </div>
                ))}
            </ul>
        </div>
    );
}; 

export default HorizontalNavBar;