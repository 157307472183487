import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import MainTemplate from '../components/mainTemplate';
import ListTemplate from '../components/listTemplate';
import { ReactComponent as EmployeesIcon } from '../assets/employees-list.svg';

import { useQuery } from '@apollo/client';
import GET_ALL_EMPLOYEES from '../queries/getAllEmployees';
import GET_LOCATION_BY_NAME from "../queries/getLocationByName";

import formatDate from '../helpers/formatDate';
import { useUser } from '../context/userContext';

const EmployeePage = () => {
    const nav = useNavigate();
    const [rowClicked, setRowClicked] = useState({});
    const [rowData, setRowData] = useState<Array<{ [key: string]: any }>>([]);
    const [sortByOutlierCount, setSortByOutlierCount] = useState<string | null>(null);
    const [locations, setLocations] = useState<LocationItem[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
    const [locationDetails, setLocationDetails] = useState(null); // State variable to hold location details

    const { userRole } = useUser();

    const { loading, error, data } = useQuery(GET_ALL_EMPLOYEES, {
        variables: {
            filter: {
                locations: selectedLocation,
                outlierCount: sortByOutlierCount,
            },
            skip: 0
        }
    });

    const { loading: locationLoading, data: locationData, refetch: refetchLocation } = useQuery(GET_LOCATION_BY_NAME, {
        skip: true,
    });
    //matching location by name on click to navigate to location detail page
    useEffect(() => {
        if (locationData && locationData.getLocationByName) {
            const locationId = locationData.getLocationByName._id;
            setLocationDetails(locationId);
            nav(`/detailLocation/${locationId}`);
        }
    }, [locationData, nav]);

    interface Employee {
        _id: string;
        location: string;
        userId: string;
        firstName: string;
        middleName?: string;
        lastName: string;
        addressStreet: string;
        addressCity: string;
        addressState: string;
        addressZip: string;
        phone: string;
        ffdStatus: string;
        outlierCount: number;
        emailCompany: string;
        driversLicenseNum: string;
        driversLicenseExpiration: string;
        locationReference: {
            locManagerName: string;
        };
    }

    interface LocationItem {
        label: string;
        href: string;
    }

    interface CellRendererParams {
        value: any;
    }

    useEffect(() => {
        if (data && data.getAllEmployees) {
            setUniqueLocations(data.getAllEmployees);
            const formattedData = data.getAllEmployees.map((employee: Employee) => {
                let outlierDisplay;
                if (typeof employee.outlierCount === "number") {
                    const totalOutliers = employee.outlierCount;

                    if (totalOutliers > 0) {
                        outlierDisplay = `${employee.outlierCount}`;
                    } else {
                        outlierDisplay = "No Outliers";
                    }
                } else {
                    outlierDisplay = "No Outliers";
                }

                return {
                    id: employee._id,
                    "DRIVER NAME": `${employee.firstName} ${employee.lastName}`,
                    "LOCATION": employee.location,
                    "PHONE": employee.phone,
                    "EMAIL": employee.emailCompany,
                    "DRIVERS LICENSE": employee.driversLicenseNum,
                    "DL EXPIRATION": formatDate(employee.driversLicenseExpiration),
                    "MANAGER":employee.locationReference.locManagerName,
                };
            });

            // Sort formattedData by "DRIVER NAME"
            const sortedData = [...formattedData].sort((a, b) => a["DRIVER NAME"].localeCompare(b["DRIVER NAME"]));
            setRowData(sortedData);
        }
    }, [data]);

    const pageTitle = "Drivers";

    const columnDefs = [
        { field: 'DRIVER NAME', sortable: true,
            comparator: (valueA: string, valueB: string) => {
              const lastNameA = valueA.split(' ').slice(-1)[0]; // Get the last word (last name)
              const lastNameB = valueB.split(' ').slice(-1)[0];
              
              if (lastNameA === lastNameB) return 0;
              return lastNameA > lastNameB ? 1 : -1;
            },flex: 1, cellClass: 'underline-location' },
        ...(userRole !== 'Manager' ? [
            {
                field: 'LOCATION',
                flex: 1,
                cellRenderer: (params: CellRendererParams) => (
                    <button className='underline-location' onClick={() => handleLocationClick(params.value)}>{params.value}</button>
                ),
            }
        ] : []),
        { field: 'PHONE', flex: 1 },
        { field: 'EMAIL', flex: 1 },
        { field: 'DRIVERS LICENSE', flex: 1 },
        { field: 'DL EXPIRATION', flex: 1 },
        { field: 'MANAGER', flex: 1 },
    ];

    const menuItems = {
        menuItemsOne: [
            { label: 'All Locations', href: '/' },
        ],
        menuItemsThree: [
            { label: 'All', href: '/' },
            { label: 'Top 10', href: '/' },
            { label: 'Top 25', href: '/' },
            { label: 'Bottom 10', href: '/' },
            { label: 'Bottom 25', href: '/' }
        ],
    };

    const dropDownData = [
        // Only pass the LOCATIONS dropdown if the user is not a Manager
        ...(userRole !== 'Manager' ? [{
            title: "LOCATIONS",
            menuItems: locations
        }] : []),
        {
            title: "OUTLIERS",
            menuItems: menuItems.menuItemsThree
        }
    ];

    const setUniqueLocations = (employees: any) => {
        const locationStrings: string[] = employees.map((employee: Employee) => employee.location);

        // Create a new array of unique location strings by turning locationStrings into a set, then back into an array
        const uniqueLocationsArray: string[] = Array.from(new Set(locationStrings)).sort();  // Sort the array alphabetically

        const allLocationsItem: LocationItem = { label: "All Locations", href: '/' };

        const uniqueLocations = [
            allLocationsItem,
            ...uniqueLocationsArray.map((location) => {
                return { label: location, href: '/' };
            })
        ];

        setLocations(uniqueLocations);
    }

    const handleRowClick = (event: any) => {
        setRowClicked(event.data.id);
        nav(`/employeedetail/${event.data.id}`, { state: { data: rowClicked } })
    }

    const handleLocationClick = async (locationName: string) => {
        try {
            const { data: locationData } = await refetchLocation({ name: locationName });
            if (locationData && locationData.getLocationByName) {
                const locationId = locationData.getLocationByName._id;
                setLocationDetails(locationId);
                nav(`/detailLocation/${locationId}`);
            }
        } catch (error) {
            console.error('Error fetching location:', error);
        }
    };

    const handleLocationChange = (location: any) => {
        if (location === "All Locations") {
            // If "All Locations" is selected, reset the filter
            setSelectedLocation(null);
        } else {
            // Otherwise, set the selected location as usual
            setSelectedLocation(location);
        }
        console.log('New Location:', location);
    };

    const handleFitForDutySelection = (outlierCount: string) => {
        if (outlierCount === "All Outliers") {
            setSortByOutlierCount(null);
        } else {
            setSortByOutlierCount(outlierCount);
        }
    };

    return (
        <div>
            <MainTemplate />
            <ListTemplate
                rowDataOne={rowData}
                handleRowClick={handleRowClick}
                // pageTitle={<Link to="/employeePage">{pageTitle}</Link>}
                pageTitle={pageTitle}
                columnDefs={columnDefs}
                dropDownData={dropDownData}
                onOutlierSelection={handleFitForDutySelection}
                onLocationSelected={handleLocationChange}
                mainIcon={<EmployeesIcon />}
            />
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>Error: {error.toString()}</p>
            ) : (
                <p>list</p>
            )}
        </div>
    );
};

export default EmployeePage;
