interface ValidationResult {
    isValid: boolean;
    errors: string[];
  }
  
  const CityValidator = (city: string): ValidationResult => {
    const errors: string[] = [];
  
  
    // 1. Required Field Validation
    if (!city) {
      errors.push("This field is required.");
    }
  
    // 2. Alphabetic Characters Only (allows letters, spaces, and hyphens)
    if (!/^[a-zA-Z\s-]+$/.test(city)) {
      errors.push("City name can only contain letters and spaces.");
    }
  
    // 3. Minimum Length Validation (2 characters)
    if (city.length < 2) {
      errors.push("City name must be at least 2 characters long.");
    }
  
    // 4. Maximum Length Validation (50 characters)
    if (city.length > 50) {
      errors.push("City name cannot exceed 50 characters.");
    }
  
    // 5. No Leading/Trailing Spaces
    // This is already covered by trimming at the start.
  
    // 6. No Consecutive Spaces
    if (/\s\s+/.test(city)) {
      errors.push("City name cannot contain consecutive spaces.");
    }
  
    // 7. Capitalization Check (First letter of each word should be capitalized)
    // if (!/^[A-Z][a-z]*(\s[A-Z][a-z]*)*$/.test(city)) {
    //   errors.push("City name should be capitalized.");
    // }
  
    return {
      isValid: errors.length === 0,
      errors,
    };
  };
  
  export default CityValidator;
  