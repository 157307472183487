import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';

interface OverdueTasksContextType {
  overdueTasks: any[];
  setOverdueTasks: (tasks: any[]) => void;
  weekTasks: any[];
  setWeekTasks: (tasks: any[]) => void;
  monthTasks: any[];
  setMonthTasks: (tasks: any[]) => void;
}

const OverdueTasksContext = createContext<OverdueTasksContextType | undefined>(undefined);

export const OverdueTasksProvider = ({ children }: { children: ReactNode }) => {
  const [overdueTasks, setOverdueTasks] = useState<any[]>(() => {
    const savedData = localStorage.getItem('overdueTasks');
    return savedData ? JSON.parse(savedData) : [];
  });

  const [weekTasks, setWeekTasks] = useState<any[]>(() => {
    const savedData = localStorage.getItem('weekTasks');
    return savedData ? JSON.parse(savedData) : [];
  });

  const [monthTasks, setMonthTasks] = useState<any[]>(() => {
    const savedData = localStorage.getItem('monthTasks');
    return savedData ? JSON.parse(savedData) : [];
  });

  useEffect(() => {
    localStorage.setItem('overdueTasks', JSON.stringify(overdueTasks));
  }, [overdueTasks]);

  useEffect(() => {
    localStorage.setItem('weekTasks', JSON.stringify(weekTasks));
  }, [weekTasks]);

  useEffect(() => {
    localStorage.setItem('monthTasks', JSON.stringify(monthTasks));
  }, [monthTasks]);

  const updateOverdueTasks = (tasks: any[]) => {
    setOverdueTasks(tasks);
  };

  const updateWeekTasks = (tasks: any[]) => {
    setWeekTasks(tasks);
  };

  const updateMonthTasks = (tasks: any[]) => {
    setMonthTasks(tasks);
  };

  return (
    <OverdueTasksContext.Provider value={{ overdueTasks, setOverdueTasks: updateOverdueTasks, weekTasks, setWeekTasks: updateWeekTasks, monthTasks, setMonthTasks: updateMonthTasks }}>
      {children}
    </OverdueTasksContext.Provider>
  );
};

export const useOverdueTasks = () => {
  const context = useContext(OverdueTasksContext);
  if (context === undefined) {
    throw new Error('useOverdueTasks must be used within a OverdueTasksProvider');
  }
  return context;
};
