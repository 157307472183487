import React from 'react'; 
import './pageTitle.css';

type PageTitleProps = {
    pageTitle:string;
}

const PageTitle = ({pageTitle}:PageTitleProps) => {
    return (
        <div id="Group_446">
		    <div id="Good_Afternoon_Joe">
			    <span>{pageTitle}</span>
		    </div>
	    </div> 
    )
}; 
export default PageTitle;