import './confirmation.css';
import LogoMobile from '../../logoMobile';

const Confirmation = () => {
  return (
    <div className="mobile-main">
        <div className="mobile-header-goat confirmation-header">
            <LogoMobile />
        </div>
        <div className="mobile-body ">
            <p className="confirmation-message">
                Thank you for completing your onboarding questionaire!
            </p>
            <p className="confirmation-submessage">
                
            </p>
            {/* <button className="confirmation-button">
                CLOSE
            </button> */}
        </div>  
      
    </div>
  );
};

export default Confirmation;
