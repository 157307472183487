// Email.js
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import ADD_EMPLOYEE from '../../queries/createEmployeeByEmail';

export default function Email() {
    const [emailPersonal, setEmailPersonal] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [addEmployee, { data, loading, error }] = useMutation(ADD_EMPLOYEE);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const locationReference = '65eb43d44f41993c85d658fd'
        try {
            await addEmployee({ variables: { input: { emailPersonal, locationReference, firstName, lastName } } });
            console.log('Employee added successfully:', data);
        } catch (e) {
            console.error('Error adding employee:', e);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
            <div>
                <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-900">
                    First Name
                </label>
                <div className="mt-2">
                    <input
                        id="firstName"
                        name="firstName"
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        placeholder="First Name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
            </div>

            <div className="mt-4">
                <label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-900">
                    Last Name
                </label>
                <div className="mt-2">
                    <input
                        id="lastName"
                        name="lastName"
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        placeholder="Last Name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
            </div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Email
                </label>
                <div className="mt-2">
                    <input
                        id="emailPersonal"
                        name="emailPersonal"
                        type="email"
                        value={emailPersonal}
                        onChange={(e) => setEmailPersonal(e.target.value)}
                        placeholder="you@example.com"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
            </div>
            <button type="submit" className="mt-4 bg-indigo-600 text-white py-2 px-4 rounded">
                Add Employee
            </button>
            {loading && <p>Loading...</p>}
            {error && <p>Error: {error.message}</p>}
            {data && (
                <div>
                    <p>Employee added successfully!</p>
                    <p>ID: {data.addEmployee._id}</p>
                    <p>Email: {data.addEmployee.emailPersonal}</p>
                </div>
            )}
        </form>
    );
}
