import React from 'react';
import { useLocation } from 'react-router-dom';
import './leftNavBar.css';
import { useNavigate } from 'react-router-dom';
import { useHandleSignOut } from '../../helpers/logout';
import { LOGOUT_MUTATION } from '../../queries/logoutMutation';
import { useMutation } from '@apollo/client';

type NavBarItem = {
    href: string;
    icon: any;
    label: string;
};

type LeftNavBarProps = {
    items: NavBarItem[];
};

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

const LeftNavBar = ({ items }: LeftNavBarProps) => {
    const location = useLocation();

    const navigate = useNavigate(); 
    const [logoutMutation] = useMutation(LOGOUT_MUTATION);

    // This function determines if the item path is the current location
    const isCurrent = (path: string) => location.pathname === path;

    const handleSignOut = async () => {
        try {
        await logoutMutation(); // Call the logout mutation
        // Perform any additional cleanup if needed
        navigate('/', { replace: true }); // Redirect to the login or home page
        } catch (error) {
        console.error('Error logging out:', error);
        }
    };
   
    return (
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-60 lg:flex-col">
            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-700 px-6 pb-4">
                <div className="flex h-16 shrink-0 items-center"> 
                </div>
                <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-2">
                        {items.map((item) => (
                            <li key={item.label}>
                                <a
                                    href={item.href}
                                    className={classNames(
                                        isCurrent(item.href) ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                        'group flex gap-x-3 rounded-md p-2 leading-6'
                                    )}
                                >
                                    <img src={item.icon} className="h-6 w-6 shrink-0" alt={item.label}/>
                                    {item.label}
                                </a>
                            </li>
                        ))}
                    </ul>
                    <button
                        className='
                            text-orange-300 rounded-md p-2 px-4 leading-6
                            border border-orange-300
                            hover:text-white hover:bg-gray-800 hover:border-transparent
                            focus:outline-none focus:ring-2 focus:ring-orange-400 focus:ring-offset-2 focus:ring-offset-gray-800
                            transition duration-300
                        '
                        onClick={handleSignOut}
                        >
                        Logout
                        </button>
                </nav>
            </div>
        </div>
    );
};

export default LeftNavBar;
