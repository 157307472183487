import { gql, useQuery } from '@apollo/client';

const GET_EMPLOYEES_WITH_OUTLIERS = gql`
  query GetEmployeesWithOutliers {
    getEmployeesWithOutliers{
        _id
        location
        outlierCount
        firstName
        lastName
        emailCompany
        phone
        driversLicenseState
        driversLicenseExpiration
        driversLicenseNum
        locationReference{
          _id
          name
          addressState
        }
        program{
          _id
          criteria{
            _id
            description
            deadline
          }
        }
    }
  }`

export default GET_EMPLOYEES_WITH_OUTLIERS;
export { GET_EMPLOYEES_WITH_OUTLIERS };