import React from 'react';
import { useNavigate } from 'react-router-dom';

import MainTemplate from '../components/mainTemplate';
import StatsComponent from '../components/statsDashboard/index';
import ChartComponent from '../components/chart/index'; 
import PageTitle from '../components/pageTitle';
import LocationsIcon from '../assets/itw_location_icon.svg';

import '../components/statsDashboard/statsDashboard.css';
import '../css/App.css';
import { DocumentChartBarIcon, ListBulletIcon } from '@heroicons/react/24/outline';

interface PieChartDataPoint {
  name: string;
  y: number;
}

interface Stat {
  name: string;
  stat: string;
  previousStat: string;
  change: string;
  changeType: 'increase' | 'decrease';
}

const stats: Stat[] = [
  { name: 'Locations', stat: '58', previousStat: '70,946', change: '12%', changeType: 'increase' },
  { name: 'Drivers', stat: '420', previousStat: '56.14%', change: '2.02%', changeType: 'increase' },
  { name: 'Revenues Last Year', stat: '$48,778,523', previousStat: '28.62%', change: '4.05%', changeType: 'decrease' },
];

const LocationAnalytics: React.FC = () => {
  const pageTitle = 'Locations';
  const chartTitle = 'REVENUES BY STATE';
  const chartCategories = ['MI', 'TN', 'CO', 'SC', 'FL', 'LA'];
  const chartData = [17000000, 28000000, 48000000, 20000000, 55000000, 32000000];
  const yAxisValue = 'USD';
  const chartTitle1 = 'LOCATIONS BY TYPE';
  const chartCategories1 = ['Cultivator', 'Processor', 'Retail'];
  const chartData1 = [7, 38, 58];
  const yAxisValue1 = 'Location Count';
  const chartTitleLine = 'REVENUE (5yr)';
  const chartCategoriesLine = ['2018', '2019', '2020', '2021', '2022'];
  const chartDataLine = [17000000, 27000000, 32000000, 42000000, 48778523];
  const yAxisDataLine = 'USD';
  const chartTitlePie = 'REVENUES BY DISTRIBUTION (5 yr)';
  const chartCategoriesPie = ['Cultivator', 'Processor', 'Retail'];
  const chartDataPieValues = [1700000, 800000, 3200000];
  const chartDataPie: PieChartDataPoint[] = chartCategoriesPie.map((name, index) => ({
    name: name,
    y: chartDataPieValues[index],
  }));

  const navigate = useNavigate();

  const [isListHovered, setIsListHovered] = React.useState(false);
  const [isDashHovered, setIsDashHovered] = React.useState(false);

  const handleListIconMouseEnter = () => {
    setIsListHovered(true);
  };

  const handleListIconMouseLeave = () => {
    setIsListHovered(false);
  };

  const handleDashIconMouseEnter = () => {
    setIsDashHovered(true);
  };

  const handleDashIconMouseLeave = () => {
    setIsDashHovered(false);
  };

  const handleDashIconClick = () => {
    if (pageTitle === 'Locations') {
      navigate('/location');
    } else if (pageTitle === 'Drivers') {
      navigate('/driver');
    }
  };

  return (
    <div className="">
      <MainTemplate />
      <div className="main-container">
          <div className="header">
            <div className="title-icons-container">
              <div className="logo-container locations-icon">
                <img src={LocationsIcon} alt="Logo" />
              </div>
              <PageTitle pageTitle="Locations" />
              <div className='map-icon-location' style={{ marginTop: '2.25vh', marginLeft: '1vw' }}>
                <div
                  onMouseEnter={handleDashIconMouseEnter}
                  onMouseLeave={handleDashIconMouseLeave}
                >
                  {isDashHovered ? (
                    <DocumentChartBarIcon className="cursor-pointer h-6 w-6 text-gray-400 pomegranate-glow" />
                  ) : (
                    <DocumentChartBarIcon className="cursor-pointer h-6 w-6 text-gray-400 pomegranate-glow"
                      style={{ color: '#e32661' }} />
                  )}
                </div>
                <div
                  onMouseEnter={handleListIconMouseEnter}
                  onMouseLeave={handleListIconMouseLeave}
                  onClick={handleDashIconClick}
                >
                  {isListHovered ? (
                    <ListBulletIcon className="cursor-pointer h-6 w-6 text-gray-500" />
                  ) : (
                    <ListBulletIcon className="cursor-pointer h-6 w-6 text-gray-400" />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 mb-4 overflow-auto">
        <StatsComponent stats={stats} onOverdueTasksClick={()=>console.log('Overdue tasks clicked')} onWeekTasksClick={()=>console.log('week stats clicked')} onMonthTasksClick={()=>console.log('month stats clicked')}/>
        <div className="flex justify-center">
          <div className="grid grid-cols-2" style={{ gap: '6rem' }}>
            <div style={{ width: '100%', height: 'auto' }}>
              <ChartComponent
                title={chartTitle1}
                categories={chartCategories1}
                data={chartData1}
                type='column'
                colors={['#E32661']}
                yAxisValue={yAxisValue1}
              />
            </div>
            <div style={{ width: '100%', height: 'auto' }}>
              <ChartComponent
                title={chartTitleLine}
                categories={chartCategoriesLine}
                data={chartDataLine}
                type='line'
                colors={['#007E9D']}
                yAxisValue={yAxisDataLine}
              />
            </div>
            <div style={{ width: '100%', height: 'auto' }}>
              <ChartComponent
                title={chartTitlePie}
                categories={chartCategoriesPie}
                data={chartDataPie}
                type='pie'
                colors={['#007E9D', '#E32661', '#FFBF59']}
                yAxisValue='USD'
              />
            </div>
            <div style={{ width: '100%', height: 'auto' }}>
              <ChartComponent
                title={chartTitle}
                categories={chartCategories}
                data={chartData}
                type='column'
                colors={['#ffbf59']}
                yAxisValue={yAxisValue}
              />
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default LocationAnalytics;
