import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { getYear, setYear } from 'date-fns';

interface YearPickerProps {
  year: number | null;
  setYear: (year: number) => void;
//   validateYear: (year: number) => void;
}

const YearPicker: React.FC<YearPickerProps> = ({ year, setYear }) => {
  const handleDateChange = (date: Date | null) => {
    if (date) {
      const selectedYear = getYear(date);
      setYear(selectedYear);
    }
  };

  return (
    <>
      <DatePicker
        selected={year ? new Date(year, 0, 1) : null}
        onChange={handleDateChange}
        showYearPicker
        dateFormat="yyyy"
        placeholderText="Select Year"
        className="year-picker"
      />
    </>
  );
};

export default YearPicker;
