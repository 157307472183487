import { Disclosure } from '@headlessui/react'
import StatsComponent from '../statsDashboard';
import ProgressBar from '../progressBar';
import { FC } from 'react';
import {
    ChevronDownIcon,
    ChevronUpIcon,
  } from '@heroicons/react/20/solid';
import { useState } from 'react';

interface Stat {
    name: string;
    stat: string;
    previousStat: string;
    change: string;
    changeType: 'increase' | 'decrease';
  }
  
const ProgramSummary: FC =() => {
    const [isExpanded, setIsExpanded] = useState(false);
    const stats: Stat[] = [
        { name: 'Overdue Tasks', stat: '0', previousStat: '70,946', change: '12%', changeType: 'increase' },
        { name: 'NEEDING REVIEW', stat: '1', previousStat: '56.14%', change: '2.02%', changeType: 'increase' },
        { name: 'Upcoming this Month', stat: '12', previousStat: '28.62%', change: '4.05%', changeType: 'decrease' },
      ];

      const toggleExpand = () => {
        setIsExpanded(!isExpanded);
      };
    return (
        <>
        <div className="flex items-center mt-3">
        <h2 className="text-xl font-semibold">Summary</h2>
        <button onClick={toggleExpand} className="text-gray-500 hover:text-gray-700">
          {isExpanded ? (
            <ChevronUpIcon className="h-8 w-8" aria-hidden="true" />
          ) : (
            <ChevronDownIcon className="h-8 w-8" aria-hidden="true" />
          )}
        </button>
      </div>
      {isExpanded && (
        <>
          <StatsComponent stats={stats} onMonthTasksClick={()=>{}} onOverdueTasksClick={()=>{}} onWeekTasksClick={()=>{}}/>
          <ProgressBar/>
        </>
    )}
        </>
    );
  }

  export default ProgramSummary;
  