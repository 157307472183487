import React from 'react';
import {Link} from 'react-router-dom';

import itwLogo from '../../assets/logo.svg';

const Logo = () => {
    return (

            <Link to="/dashboard">
                <img src={itwLogo} alt="Logo" width={50}/>
            </Link>
    );
};

export default Logo;

//
// import React from 'react';
// import {Link} from 'react-router-dom';
// import './itwIcon.css';
// import itwLogo from '../../assets/logo.svg';
//
// const ItwLogo = () => {
//     return (
//         <div className="logo-container">
//             <Link to="/dashboard">
//                 <img src={itwLogo} alt="Logo" />
//             </Link>
//         </div>
//     );
// };
//
// export default ItwLogo;
