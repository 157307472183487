import './displayData.css';
import React from 'react';

interface InformationRow {
  label: string;
  value: string;
}

interface InformationProps {
  sections: {
    title: string;
    data: InformationRow[];
  }[];
}

const InformationComponent = ({ sections }: InformationProps) => {
  // sections.forEach(section => {
  //   //this is for scrolling to a space on the screen where the section is located
  //   const id = section.title;
  // });
  console.log("section title from InformationComponent as id: ", sections[0].title.replace(/\s+/g, '-').toLowerCase())

  return (
    <div className="information-container">
      <div>
        <dl className="divide-y divide-gray-200" >
      {sections.map((section, sectionIndex) => (
        <div 
          key={sectionIndex}
          id= {section.title.replace(/\s+/g, '-').toLowerCase()} 
        >
            <div className="mt-6 px-4 sm:px-0">
              <h3 className="text-base font-semibold leading-7 text-gray-900">{section.title}</h3>
            </div>
          {section.data.map((item, itemIndex) => (
            <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-0 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">{item.label}</dt>
              {/* <span className="information-value">{item.value}</span> */}
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{item.value}</dd>
            </div>
          ))}
        </div>
      ))}
        </dl>
      </div>
    </div>
  );
};

export default InformationComponent;
