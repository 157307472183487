interface ValidationResult {
    isValid: boolean;
    errors: string[];
  }
  
  const AddressValidator = (address: string): ValidationResult => {
    const errors: string[] = [];
  
    // 1. Required Field Validation
    if (!address) {
      errors.push("This field is required.");
    }
  
    // 2. Minimum Length Validation (5 characters)
    if (address.length < 5) {
      errors.push("Address must be at least 5 characters long.");
    }
  
    // 3. Maximum Length Validation (100 characters)
    if (address.length > 100) {
      errors.push("Address cannot exceed 100 characters.");
    }
  
    // 4. Valid Characters Check
    if (!/^[a-zA-Z0-9\s,.#'-]+$/.test(address)) {
      errors.push("Address contains invalid characters.");
    }
  
    // 5. No Leading/Trailing Spaces

  
    // 6. No Consecutive Spaces
    if (/\s\s+/.test(address)) {
      errors.push("Address cannot contain consecutive spaces.");
    }
  
    // 7. Proper Number Placement (starts with a number)
    if (!/^\d+/.test(address)) {
      errors.push("Address should start with a number.");
    }
  
    return {
      isValid: errors.length === 0,
      errors,
    };
  };
  
  export default AddressValidator;
  