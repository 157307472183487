interface ValidationResult {
    isValid: boolean;
    errors: string[];
  }
  
  const PhoneValidator = (phone: string): ValidationResult => {
    const errors: string[] = [];
  
    // 1. Required Field Validation
    if (!phone) {
      errors.push("This field is required.");
    }
  
    // 2. Numeric Characters Only (allows spaces, hyphens, parentheses)
    if (!/^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/.test(phone) || phone.length !== 10) {
      errors.push("Phone number must be a valid 10-digit number.");
    }
  
    return {
      isValid: errors.length === 0,
      errors,
    };
  };
  
  export default PhoneValidator;
  