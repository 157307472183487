import LogoMobile from "../../logoMobile";
import '../../../css/mobileDriverOnboard.css';
import './progressDots.css';

const Header = () => {
  return (
    <>
      <div className = "mobile-header">
          <LogoMobile />
          <h1 className='mobile-header-text'>ACCOUNT SETUP</h1>
        </div>
        {/* <div className="progress-dots">
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
        </div> */}
    </>
  );
};

export default Header;
