import { UserIcon, PhoneIcon, EnvelopeIcon, MapPinIcon, DocumentIcon, ExclamationCircleIcon } from "@heroicons/react/20/solid"

interface DriverInfoRightSlideOverProps {
    name: String;
    phone: String;
    emailCompany: String;
    location: String;
    licenseNumber: String;
    licenseExpiration: String;
}

const DriverInfoRightSlideOver = ({ 
    name, phone, emailCompany, location, licenseNumber, licenseExpiration }: DriverInfoRightSlideOverProps) => {
    return(
        <div className="contact-info-container bg-white sm:rounded-lg ml-0 mt-3">
            <div className="px-4 sm:px-2">
            <div className="">
                <dl>
                <div className="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500 flex items-center">
                <UserIcon className="h-5 w-5 text-gray-400 mr-4" aria-hidden="true" />
                Name
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">{name}
                <div className="status mt-2 text-center text-sm font-medium">
            {/* <span className={`status-indicator ${isActive ? 'status-active' : 'status-inactive'} ml-4`}>
            </span> */}
            </div>
                </dd>
                </div>
                <div className="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500 flex items-center">
                    <PhoneIcon className="h-5 w-5 text-gray-400 mr-4" aria-hidden="true" />
                    Phone
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{phone}</dd>
                </div>
                <div className="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-6">
                <dt className="text-sm font-medium text-gray-500 flex items-center">
                    <EnvelopeIcon className="h-5 w-5 text-gray-400 mr-4" aria-hidden="true" />
                    Email
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">{emailCompany}</dd>
                </div>
                <div className="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500 flex items-center">
                    <MapPinIcon className="h-5 w-5 text-gray-400 mr-4" aria-hidden="true" />
                    Location
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{location}</dd>
                </div>
                <div className="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500 flex items-center">
                    <DocumentIcon className="h-5 w-5 text-gray-400 mr-4" aria-hidden="true" />
                    License Number
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{licenseNumber}</dd>
                </div>
                <div className="bg-white px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500 flex items-center">
                    <ExclamationCircleIcon className="h-5 w-5 text-gray-400 mr-4" aria-hidden="true" />
                    License Expiration
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{licenseExpiration}</dd>
                </div>
                </dl>
            </div>
        </div>
    </div>
    )
}

export default DriverInfoRightSlideOver;

