import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface ChartComponentProps {
  title: string;
  categories?: string[];
  data: number[] | { name: string; y: number }[];
  type: string;
  colors: string[];
  yAxisValue?: string;
}

const ChartComponent: React.FC<ChartComponentProps> = ({ yAxisValue, title, categories, data, type, colors }) => {
  const options: Highcharts.Options = {
    chart: {
      type: type,
    },
    title: {
      text: title,
      style: {
        fontSize: '16px',
        color: 'gray', 
      },
    },
    xAxis: type !== 'pie' ? {
      categories: categories,
      title: {
        text: null,
      },
    } : undefined,
    yAxis: type !== 'pie' ? {
      title: {
        text: yAxisValue,
      },
      labels: {
        formatter: function() {
          const value = Number(this.value);
          if (value >= 1000000) {
            return (value / 1000000).toFixed(1) + 'M';
          } else if (value >= 1000) {
            return (value / 1000).toFixed(1) + 'k';
          }
          return value.toString();
        },
      },
    } : undefined,
    colors: colors,
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      formatter: function() {
        const value = type === 'pie' ? this.point.y : this.y;
        let formattedValue: string;

        if (yAxisValue === 'USD' || this.series.name === 'REVENUES BY DISTRIBUTION (5 yr)') {
          formattedValue = `$${(value ?? 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
        } else {
          formattedValue = (value ?? 0).toLocaleString();
        }

        return type === 'pie'
          ? `<b>${this.point.name}</b>: ${formattedValue}`
          : `<b>${this.x}</b>: ${formattedValue}`;
      },
    },
    series: [{
      type: type as any,
      data: type === 'pie' ? (data as { name: string; y: number }[]) : data,
    }],
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </div>
  );
};

export default ChartComponent;
