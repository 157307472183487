import React from 'react';

type BadgeFlatWithDotProps = {
    title: React.ReactNode;
    status: 'fit' | 'unfit'; // Explicit status prop
};

const BadgeFlatWithDot: React.FC<BadgeFlatWithDotProps> = ({ title, status }) => {
    let bgColor = status === 'fit' ? 'bg-green-100' : 'bg-red-100';
    let textColor = status === 'fit' ? 'text-black' : 'text-red-700';
    let fillColor = status === 'fit' ? 'fill-custom-green' : 'fill-red-500';
    console.log("title", title, "status", status)
    return (
        <span style={{marginBottom:'.5rem'}} className={`inline-flex items-center gap-x-1.5 rounded-md ${bgColor} px-2 py-1 text-s font-medium ${textColor}`}>
            <svg className={`h-1.5 w-1.5 ${fillColor}`} viewBox="0 0 6 6" aria-hidden="true">
                <circle cx={3} cy={3} r={3} />
            </svg>
            {title}
        </span>
    );
};

export default BadgeFlatWithDot;
