import { useNavigate } from 'react-router-dom';
import { useUploadContext } from '../../../context/uploadProvider';
import './upload.css';  
import Header from '../progressDots/index';

function DriverLicenseUpload(): JSX.Element {

  //pulls the uploadType to determine if the user is uploading a driver's license or insurance card
  //also sets the captureSide and nextCaptureSide to determine which side of the document to capture first
  const { uploadType, setCaptureSide, setNextCaptureSide } = useUploadContext();
  const navigate = useNavigate();

  const handleTakePhotos = (side:string, nextSide:string) => {
    setCaptureSide(side);
    setNextCaptureSide(nextSide);
    navigate('/mobileDriverOnboard/capture');
  };

  return (
    <div className="mobile-main-camera">
      <Header />
      <div className='mobile-body dl-upload'>
        <h2 className='upload-h2'>Get Ready to Snap Your {uploadType === 'driverLicense' ? "Driver's License." : "Insurance Card."}</h2>
        <p className="upload-para">
          <br />1. Step into good lighting to take a picture
          <br />2. Click "Continue"
          <br />3. Grant camera access to save time inputting your info
        </p>

        <div className="image-placeholder">
          <div className="image-placeholder-icon"></div>
          <div className="image-placeholder-text"></div>
        </div>
        <div className="photo-button-container">
          <button className="photo-button" onClick={() => handleTakePhotos('front', 'back')}>TAKE PHOTO</button>
        </div>
      </div>
    </div>
  );
}

export default DriverLicenseUpload;
