interface ValidationResult {
    isValid: boolean;
    errors: string[];
  }
  
  const NameValidator = (name: string): ValidationResult => {
    const errors: string[] = [];
  
    // Trim the name once to avoid trimming multiple times in each check
    // const trimmedName = name.trim();

    // Required Field Validation
    if (!name) {
        errors.push("This field is required.");
    }

  // 2. Alphabetic Characters, Spaces, Hyphens, and Apostrophes Only
  if (!/^[a-zA-Z\s'-]+$/.test(name)) {
    errors.push("Name can only contain letters, spaces, hyphens, and apostrophes.");
  }

    // 3. Minimum Length Validation (2 characters)
    if (name.length < 2) {
        errors.push("Name must be at least 2 characters long.");
    }

      // 4. Maximum Length Validation (50 characters)
    if (name.length > 50) {
        errors.push("Name cannot exceed 50 characters.");
    }

    // 5. No Consecutive Spaces
    if (/\s\s+/.test(name)) {
        errors.push("Name cannot contain consecutive spaces.");
    }

    // 6. First Letter Capitalization
    if (!/^[A-Z]/.test(name)) {
        errors.push("Name should start with a capital letter.");
    }
  
    return {
      isValid: errors.length === 0,
      errors,
    };
  };
  
  export default NameValidator;
  