import { gql } from '@apollo/client';

const ADD_EMPLOYEE = gql`
    mutation AddEmployee($input: EmployeeInput) {
        addEmployee(input: $input) {
            _id
            emailPersonal
            firstName
            lastName
            locationReference {
                _id
            }
        }
    }
`;
export default ADD_EMPLOYEE;
export { ADD_EMPLOYEE };