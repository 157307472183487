import { gql } from '@apollo/client';

const GET_LOCATION_BY_NAME = gql`
    query GetLocationByName($name: String!) {
        getLocationByName(name: $name) {
            _id
            locationId
            buildingId
            operationType
            numFtEmployees
            numPtEmployees
            name
            locManagerName
            locManagerEmail
            locManagerPhone
            secondContactName
            secondContactEmail
            secondContactTitle
            vehicles {
                _id
                make
                model
                vin
                roadReadyStatus
                departmentAssociation
                annualMileage
                leasedOrOwned
                lessor
                licensePlateNumber
                vehicleUsageStatus
                location
            }
            employees {
                location
                firstName
                lastName
                phone
                emailCompany
                driversLicenseNum
                driversLicenseExpiration
                mvrScore
            }
            addressStreet
            addressCity
            addressState
            addressZip
            hoursOfOperation
            fitForDutyPercent
            licensedEntityName
            licensedDBA
            licenseLocationAddress
            licenseState
            licenseType
            licenseOrPermitNumber
            licenseRegulator
            licenseEffectiveDate
            licenseExpirationDate
            licenseCost
            licenseStatus
            typeOfOperationsConducted
            ownedOrLeased
            ownerName
            ownerEmail
            ownerPhone
            buildingSqFt
            isActive
            status
            createdAt
            updatedAt
            lastUpdateByUser
            complianceViolations
            licenseRenewalSuccessRate
            auditFindings
            regulatoryFinesPenalties
            leaseExpirationDate
            totalInsuranceValue
            costOfGoodsSold
            grossProfitMargin
            operatingExpenseRatio
            inventoryTurnoverRate
            productionEfficiency
            fulfillmentDistributionEfficiency
            incidentRate
            complianceWithSafetyRegulations
            healthSafetyTrainCompleteRate
            communityImpactMetrics
            sustainabilityInitiatives
            diversityInclusionMetrics
        }
    }
`;

export default GET_LOCATION_BY_NAME;
export { GET_LOCATION_BY_NAME };
