import { gql } from '@apollo/client';

const GET_EMPLOYEE = gql`
  query GetEmployee($id: ID!) {
    getEmployeeById(id: $id) {
        _id
        userId
        position
        firstName
        lastName
        addressStreet
        addressCity
        addressState
        addressZip
        phone
        emailPersonal
        emailCompany
        dob
        gender
        martialStatus
        emergencyContactName
        relationshipToEmployee
        emergencyContactPhone
        startDate
        stopDate
        hireDate
        createdDate
        lastUpdate
        lastUpdateByUser
        ffdStatus
        mvrDate
        mvrScore
        mvrState
        expirationDate
        employmentStatus
        driversLicenseNum
        driversLicenseExpiration
        location
        program
        {
          locationName
          criteria{
            _id
            name
            criterionStatus
            description
            deadline
          }
        }
    }
  }`

export default GET_EMPLOYEE;
export { GET_EMPLOYEE };