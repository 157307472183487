import { gql } from '@apollo/client';

const UPDATE_SAMBA_BY_CRITERION_ID = gql`
  mutation UpdateSambaByCriterionId($criterionId: ID!, $input: SambaInput!) {
    updateSambaByCriterionId(criterionId: $criterionId, input: $input) {
      _id
      sambaPersonId
      mvrScore
      mvrState
      correctiveAction
      criterionId{
        _id
      }
    }
  }
`;

export { UPDATE_SAMBA_BY_CRITERION_ID };
