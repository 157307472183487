import { gql } from '@apollo/client';

const GET_LOCATION = gql`
  query GetLocation($id: ID!) {
    getLocation(id:$id){
      associatedEntity 
      locationId 
      buildingId 
      operationType 
      numFtEmployees 
      numPtEmployees 
      name 
      locManagerName 
      locManagerEmail 
      locManagerPhone 
      secondContactName 
      secondContactEmail 
      secondContactTitle 
      vehicles { 
        _id
        make
        model
        vin
        roadReadyStatus
        departmentAssociation
        annualMileage
        leasedOrOwned
        lessor
        licensePlateNumber
        vehicleUsageStatus
        location
      } 
      employees { 
        _id
        location
        firstName
        lastName
        phone 
        emailCompany
        driversLicenseNum
        driversLicenseExpiration
        mvrScore 
      }
      addressStreet 
      addressCity 
      addressState 
      addressZip 
      hoursOfOperation 
      fitForDutyPercent 
      licensedEntityName 
      licensedDBA 
      licenseLocationAddress 
      licenseState 
      licenseType 
      licenseOrPermitNumber 
      licenseRegulator 
      licenseEffectiveDate 
      licenseExpirationDate 
      licenseCost 
      licenseStatus 
      typeOfOperationsConducted 
      ownedOrLeased 
      ownerName 
      ownerEmail 
      ownerPhone 
      buildingSqFt 
      isActive 
      status 
      createdAt 
      updatedAt 
      lastUpdateByUser 
      complianceViolations 
      licenseRenewalSuccessRate 
      auditFindings 
      regulatoryFinesPenalties 
      leaseExpirationDate 
      totalInsuranceValue 
      costOfGoodsSold 
      grossProfitMargin 
      operatingExpenseRatio 
      inventoryTurnoverRate 
      productionEfficiency fulfillmentDistributionEfficiency incidentRate complianceWithSafetyRegulations healthSafetyTrainCompleteRate communityImpactMetrics sustainabilityInitiatives diversityInclusionMetrics
      outlierCount
    }
  }
`
export default GET_LOCATION;
export { GET_LOCATION };