import { Disclosure } from '@headlessui/react'
import Calendar from '../dashboardCalendar';
import ProgramSummary from '../programSummary';
import ProgramUpcoming from '../programUpcoming';
import ProgramPerformance from '../programPerformance';

interface NavItem {
    label: string;
    viewId: string;
  }
  
  interface ProgramTertNavProps {
    activeView: string;
    setActiveView: React.Dispatch<React.SetStateAction<string>>;
    navItems: NavItem[];
  }
  
  export default function ProgramTertNav({activeView, setActiveView, navItems}: ProgramTertNavProps) {
    console.log('activeView:', activeView);
  
    return (
        <>
        <Disclosure as="nav" className='bg-white border-b border-gray-400 mt-0 mb-0'>
            {({ open }) => (
                <>
                    <div className=" max-w-7xl px-2 sm:px-0">
                        <div className="relative flex h-12 justify-between">
                            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                                <div className="hidden sm:flex sm:space-x-8">
                                    {navItems.map(({ label, viewId }) => (
                                        <a
                                            key={viewId}
                                            href="#"
                                            // onClick={() => setActiveView(viewId)}
                                            className={`inline-flex items-center px-1 pt-1 text-sm font-medium ${activeView === viewId ? "text-gray-700 border-b-2 font-bold" : "text-gray-500 hover:text-gray-900"}`}
                                            style={{ 
                                              color: activeView === viewId ? '#007e9d' : '#555555',
                                              borderBottom: activeView === viewId ? '2px solid #007e9d' : 'none' }}
                                        >
                                            {label.toUpperCase()}
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Disclosure>
        <ProgramSummary />
        <Calendar rowData={[]} onCalendarClick={()=>{}}/>
        <ProgramUpcoming />
        <ProgramPerformance />
        </>
    );
  }
  