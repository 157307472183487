import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import './rightpopover.css';

import Notification from '../notification';
import BadgeFlatWithDot from '../badgeFlatWithDot';
import SlideOverNav from '../slideOverNav';
import RequiredInfoCriterionSlideOver from '../requiredInfoCriterionSlideOver';
import DriverInfoRightSlideOver from '../driverInfoRightSlideOver';

import { useQuery, useMutation } from '@apollo/client';
import { GET_SAMBA_BY_CRITERION_ID } from '../../queries/getSambaByCriterionId';
import { UPDATE_SAMBA_BY_CRITERION_ID } from '../../queries/updateSambaByCriterionId';
import { useNavigate } from "react-router-dom";

interface RightPopoverProps {
    closePopover: () => void; 
    title: string;
    emailCompany: string;
    phone: string;
    name: string;
    employeeId: number;
    location: string;
    licenseNumber: string;
    licenseExpiration: string;
    flyOut: string;
    isActive: boolean;
    itemCount: number;
    activeIndex: number;
    navigateToNext: () => void;
    toggleEdit: () => void;
}

const RightPopover = ({ closePopover, title, emailCompany, phone, name, employeeId, location, licenseNumber, licenseExpiration, flyOut, isActive, itemCount, activeIndex, navigateToNext }: RightPopoverProps) => {
  const [open, setOpen] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [activeView, setActiveView] = useState('requiredInfo');
  const [isReadOnly, setIsReadOnly] = useState(true);
  const criterionId = '6601c7faef4a65c6eec1c6e7'
  const { data: queryData, loading: queryLoading, error: queryError } = useQuery(GET_SAMBA_BY_CRITERION_ID, {
    variables: { criterionId },
  });
  
  const [sambaPersonId, setSambaPersonId] = useState('');
  const [mvrScore, setMvrScore] = useState('');
  const [mvrState, setMvrState] = useState('');
  const [mvrDate, setMvrDate] = useState('');
  const [correctiveAction, setCorrectiveAction] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (queryData && queryData.getSambaByCriterionId) {
      const { sambaPersonId, mvrScore, mvrState, mvrDate, correctiveAction } = queryData.getSambaByCriterionId;
      setSambaPersonId(sambaPersonId);
      setMvrScore(mvrScore);
      setMvrState(mvrState);
      setMvrDate(mvrDate);
      setCorrectiveAction(correctiveAction);
    }
  }, [queryData]);

  const [updateSambaByCriterionId, { data: mutationData, loading: mutationLoading, error: mutationError }] = useMutation(UPDATE_SAMBA_BY_CRITERION_ID);

  const badgeStatus = isActive ? 'fit' : 'unfit';
  const handleUpdate = async () => {
   
    try {
      await updateSambaByCriterionId({
        variables: {
          criterionId,
          input: {
            sambaPersonId,
            mvrScore: mvrScore.toString(),
            mvrState,
            mvrDate,
            correctiveAction,
          },
        },
      });
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 3000);
    } catch (err) {
      console.error('Error updating Samba:', err);
    }
  };

  const handleNameClick = () => {
    navigate(`/employeeDetail/${employeeId}`);
  };

  const handleClose = () => {
    setOpen(false);  // Close the popover and trigger the exit animation
    closePopover();  // If closePopover does extra cleanup, call it here
  };
console.log("open", open)
  if (queryLoading) return <p>Loading...</p>;
  if (queryError) return <p>Error: {queryError.message}</p>;
  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        {/* onClose used to be set to closePopover -- investigate which is the best approach */}
        <Dialog as="div" className="relative z-10" onClose={handleClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-custom-color transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-hidden">
            {showNotification && <Notification />}
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-2xl">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="button-class absolute right-0 top-0 flex pr-3 pt-4 sm:pr-5">
                        <button
                          type="button"
                          className="relative rounded-md text-black hover:text-grey focus:outline-none"
                          onClick={closePopover}
                        >
                          <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex h-full flex-col bg-white shadow-xl">
                      <div className="">
                        <Dialog.Title className="text-1xl font-normal leading-6 text-gray-900 title-dark-gray" style={{ textTransform: 'uppercase' }}>
                          <div className="ml-1">
                            {title}
                          </div>
                        </Dialog.Title>
                      </div>
                      <div className="flex-1 overflow-y-auto p-4">
                        <div className="bg-white sm:rounded-lg">
                          <div className="px-4 py-2">
                            <div>
                              <dl>
                                <div className="bg-white px-4 py-2 sm:grid  sm:gap-4 sm:px-6">
                                  <h1 className="mt-1 text-2xl text-gray-900 mb-0 sm:col-span-2 flex items-center" onClick={handleNameClick}>
                                    <span className="underline-location">{name}</span>
                                    <div className="status mt-0 text-center text-sm font-medium">
                                      <span className={`status-indicator ${isActive ? 'status-active' : 'status-inactive'} custom-margin-left`}>
                                        <BadgeFlatWithDot
                                          title={<span>{isActive ? 'COMPLETED' : 'NOT COMPLETED'}</span>}  
                                          status={badgeStatus}
                                        />
                                      </span>
                                    </div>
                                  </h1>
                                </div>
                                <div className="bg-white px-2 py-1 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-6">
                                  <p className="text-sm text-gray-500 mb-0 sm:mt-0 sm:col-span-2">{phone}</p>
                                </div>
                                <div className="bg-white px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-6">
                                  <h3 className="text-sm text-gray-500 mb-0 sm:mt-0 sm:col-span-2">{emailCompany}</h3>
                                </div>
                              </dl>
                            </div>
                          </div>
                          <SlideOverNav
                            activeView={activeView}
                            setActiveView={setActiveView}
                            navItems={[
                              { label: 'Required Info', viewId: 'requiredInfo' },
                              { label: 'Driver', viewId: 'driver' },
                              { label: 'History', viewId: 'history' },
                            ]}
                          />
                          {showNotification && <Notification />}
                          <div className="content-area">
                            {activeView === 'history' && <div>Content for History</div>}
                            {activeView === 'requiredInfo' && title === 'Samba Enrollment' && (
                              <RequiredInfoCriterionSlideOver
                                sambaPersonId={sambaPersonId}
                                handleSambaPersonIdChange={(e) => setSambaPersonId(e.target.value)}
                                mvrScore={mvrScore}
                                handleMvrScoreChange={(e) => setMvrScore(e.target.value)}
                                mvrState={mvrState}
                                handleMvrStateChange={(e) => setMvrState(e.target.value)}
                                mvrDate={mvrDate}
                                handleMvrDateChange={(e) => setMvrDate(e.target.value)}
                                correctiveAction={correctiveAction}
                                handleCorrectiveActionChange={(e) => setCorrectiveAction(e.target.checked)}
                                isReadOnly={isReadOnly}
                                toggleEdit={() => setIsReadOnly(!isReadOnly)}
                              />
                            )}
                            {activeView === 'driver' && (
                              <DriverInfoRightSlideOver
                                name={name}
                                phone={phone}
                                emailCompany={emailCompany}
                                location={location}
                                licenseNumber={licenseNumber}
                                licenseExpiration={licenseExpiration}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="sticky bottom-0 bg-white p-4">
                        <hr className="border-t border-gray-400 mt-4 mb-4" />
                        <div className="action-buttons flex justify-end gap-3">
                          <button className="cancel-next-btn text-xs text-black py-1 px-4">
                            CANCEL
                          </button>
                          <button onClick={handleUpdate} className="update-btn text-white py-1 px-4">
                            SAVE
                          </button>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default RightPopover;
