import React, { useState, useEffect } from 'react';
import checkmark from  '../../assets/checkmark.svg' 
import theX from '../../assets/theX.svg'
import thex2 from '../../assets/thex2.svg'
import './ffdEmployeeChecklist.css'

export interface RowItem {
    id: number;
    content: any;
    completedOn?: any;
}

interface FfdEmployeeChecklistProps {
    onItemClicked: (index: number) => void; 
    rowItems:RowItem[];
  }

const FfdEmployeeChecklist = ({ onItemClicked, rowItems }: FfdEmployeeChecklistProps) => {
const completedOn = ["May 05, 2024", "May 06, 2024", "--"]
    return (
        <div className="icon-size-check-x">
            <div className="ffd-header">
                <div className="ffd-column-title">Criterion</div>
                <div className="ffd-column-title">Completed on</div>
            </div>
            {rowItems.map((item, index) => (
                <div
                    key={item.id}
                    style={{
                        padding: '10px',
                        display: 'flex', 
                        alignItems: 'center', 
                        marginLeft: '-.75%'
                    }}
                    onClick={() => onItemClicked(index)}
                >
                    <div className="ffd-column" style={{ flex: 1 }}>
                        <span>{item.content}</span>
                    </div>
                    <div className="ffd-column" style={{ flex: 1 }}>
                        <span>{completedOn[index]}</span> 
                    </div>
                </div>
            ))}
        </div>
    );
};
  
  export default FfdEmployeeChecklist;