import { FC, useState, useEffect } from 'react';
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  MapPinIcon,
  BellIcon
} from '@heroicons/react/20/solid';
import formatDate from '../../helpers/formatDate';

interface Event {
  id: number;
  DATE: string;
  time: string;
  datetime: string;
  NAME: string;
  LOCATIONS: string;
  TYPE: string;
  email: string;
  phone: string;
  licenseNumber: string;
  active: boolean;
}

interface Day {
  date: string;
  isCurrentMonth?: boolean;
  isToday?: boolean;
  isSelected?: boolean;
  hasEvent?: boolean;
}

interface CalendarProps {
  rowData: Event[];
  onCalendarClick: (event: Event) => void;
}

const generateDaysForMonth = (rowData: Event[], currentDate: Date): Day[] => {
  const days: Day[] = [];
  // const eventDates = new Set(rowData.map((rd) => new Date(rd.DATE).toISOString().split('T')[0]));
  // console.log('eventDates', eventDates);
  const eventDates = new Set<string>();

  rowData.forEach((rd, index) => {
    const parsedDate = new Date(rd.DATE).toISOString().split('T')[0];
    eventDates.add(parsedDate);
  });

  console.log('eventDates', eventDates);

  // Get the first and last day of the current month
  const startDate = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), 1));
  const endDate = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth() + 1, 0));

  // Get the day of the week for the first day of the month (0 = Sunday, 6 = Saturday)
  const startDayOfWeek = startDate.getDay();
  console.log('startDayOfWeek', startDayOfWeek);

  // Add days from the previous month to fill the empty slots at the beginning of the week
  for (let i = startDayOfWeek; i > 0; i--) {
    const prevDate = new Date(startDate);
    prevDate.setUTCDate(startDate.getUTCDate() - i);
    const formattedDate = prevDate.toISOString().split('T')[0];
    days.push({
      date: formattedDate,
      isCurrentMonth: false,
      isToday: formattedDate === new Date().toISOString().split('T')[0],
      hasEvent: eventDates.has(formattedDate),
    });
  }

  // Add days for the current month
  for (let date = new Date(startDate); date <= endDate; date.setUTCDate(date.getUTCDate() + 1)) {
    const formattedDate = date.toISOString().split('T')[0];
    days.push({
      date: formattedDate,
      isCurrentMonth: true,
      isToday: formattedDate === new Date().toISOString().split('T')[0],
      hasEvent: eventDates.has(formattedDate),
    });
  }

  // Add days from the next month to fill the empty slots at the end of the month
  const endDayOfWeek = endDate.getUTCDay();
  for (let i = 1; i < 7 - endDayOfWeek; i++) {
    const nextDate = new Date(endDate);
    nextDate.setUTCDate(endDate.getUTCDate() + i);
    const formattedDate = nextDate.toISOString().split('T')[0];
    days.push({
      date: formattedDate,
      isCurrentMonth: false,
      isToday: formattedDate === new Date().toISOString().split('T')[0],
      hasEvent: eventDates.has(formattedDate),
    });
  }
  return days;
};


const classNames = (...classes: (string | boolean | undefined)[]) => {
  return classes.filter(Boolean).join(' ');
};

const Calendar: FC<CalendarProps> = ({ rowData, onCalendarClick }) => {
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [days, setDays] = useState<Day[]>([]);
  const [eventsForSelectedDate, setEventsForSelectedDate] = useState<Event[]>(rowData);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0];
    // setSelectedDate(formattedToday);
    console.log("selectedDate", selectedDate);

    const generatedDays = generateDaysForMonth(rowData, currentDate);
    setDays(generatedDays);
  }, [currentDate, rowData]);

  const handleDateClick = (date: string) => {
    const clickedDate = new Date(date);
    const formattedDate = clickedDate.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });

    setSelectedDate(date);
    console.log('date', date);
    console.log('formattedDate', formattedDate);

    const filteredEvents = rowData.filter((rd) => new Date(rd.DATE).toISOString().split('T')[0] === date);
    setEventsForSelectedDate(filteredEvents);
    console.log("filteredEvents", filteredEvents);
  };

  const handlePreviousMonth = () => {
    const prevMonth = new Date(currentDate);
    prevMonth.setMonth(currentDate.getMonth() - 1);
    setCurrentDate(prevMonth);
  };

  const handleNextMonth = () => {
    const nextMonth = new Date(currentDate);
    nextMonth.setMonth(currentDate.getMonth() + 1);
    setCurrentDate(nextMonth);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <div className="flex  items-center mt-3">
        <h2 className="text-xl font-semibold">Calendar</h2>
        <button onClick={toggleExpand} className="text-gray-500 hover:text-gray-700">
          {isExpanded ? (
            <ChevronUpIcon className="h-8 w-8" aria-hidden="true" />
          ) : (
            <ChevronDownIcon className="h-8 w-8" aria-hidden="true" />
          )}
        </button>
      </div>
      {isExpanded && (
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
          <div className="mt-10 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-9 xl:col-start-6.5">
            <div className="flex items-center text-gray-900">
              <button
                type="button"
                className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                onClick={handlePreviousMonth}
              >
                <span className="sr-only">Previous month</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              <div className="flex-auto text-xl font-semibold">
                {currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
              </div>
              <button
                type="button"
                className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                onClick={handleNextMonth}
              >
                <span className="sr-only">Next month</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
              <div>M</div>
              <div>T</div>
              <div>W</div>
              <div>T</div>
              <div>F</div>
              <div>S</div>
              <div>S</div>
            </div>
            <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
              {days.map((day, dayIdx) => (
                <button
                  key={day.date}
                  type="button"
                  onClick={() => handleDateClick(day.date)}
                  className={classNames(
                    'relative py-1.5 hover:bg-gray-100 focus:z-10',
                    day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                    (selectedDate === day.date || day.isToday) && 'font-semibold',
                    selectedDate === day.date && 'text-white',
                    selectedDate !== day.date && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                    selectedDate !== day.date && !day.isCurrentMonth && !day.isToday && 'text-gray-400',
                    day.isToday && selectedDate !== day.date && 'text-indigo-600',
                    dayIdx === 0 && 'rounded-tl-lg',
                    dayIdx === 6 && 'rounded-tr-lg',
                    dayIdx === days.length - 7 && 'rounded-bl-lg',
                    dayIdx === days.length - 1 && 'rounded-br-lg'
                  )}
                >
                  <time
                    dateTime={day.date}
                    className={classNames(
                      'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                      selectedDate === day.date && day.isToday && 'bg-indigo-600',
                      selectedDate === day.date && !day.isToday && 'bg-gray-900'
                    )}
                  >
                    {day.date.split('-').pop()?.replace(/^0/, '')}
                  </time>
                  {day.hasEvent && <div className="absolute bottom-1 right-1 h-1.5 w-1.5 rounded-full bg-red-500"></div>}
                </button>
              ))}
            </div>
          </div>
          <ol className="mt-4 divide-y divide-gray-100 text-sm leading-6 lg:col-span-7 xl:col-span-6">
            {eventsForSelectedDate.length > 0 ? (
              eventsForSelectedDate.map((event, index) => (
                <li key={index} className="relative flex space-x-6 py-6 xl:static">
                  <div className="flex-auto">
                    <h3
                      className="pr-10 font-semibold text-gray-900 text-lg xl:pr-0 cursor-pointer"
                      onClick={() => onCalendarClick(event)}
                    >
                      {event.NAME}
                    </h3>
                    <dl className="mt-2 flex flex-col text-gray-500 xl:flex-row">
                      <div className="flex items-start space-x-3">
                        <dt className="mt-0.5">
                          <span className="sr-only">Date</span>
                          <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </dt>
                        <dd>
                          <time dateTime={event.datetime}>
                            {formatDate(event.DATE)}
                          </time>
                        </dd>
                      </div>
                      <div className="mt-2 flex items-start space-x-3 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                        <dt className="mt-0.5">
                          <span className="sr-only">Criterion</span>
                          <BellIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </dt>
                        <dd className="cursor-pointer" onClick={() => onCalendarClick(event)}>
                          {event.TYPE}
                        </dd>
                      </div>
                      <div className="mt-2 flex items-start space-x-3 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                        <dt className="mt-0.5">
                          <span className="sr-only">Location</span>
                          <MapPinIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </dt>
                        <dd>{event.LOCATIONS}</dd>
                      </div>
                    </dl>
                  </div>
                </li>
              ))
            ) : (
              <div className="text-center py-6 text-gray-500">No Events On This Day</div>
            )}
          </ol>
        </div>
      )}
    </div>
  );
};

export default Calendar;
