import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import FIND_EMAIL from '../../queries/findEmail';
import { useLazyQuery } from '@apollo/client';

interface ForgotPasswordProps {
    showForgotPassword: boolean;
    setShowForgotPassword: (value: boolean) => void;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ showForgotPassword, setShowForgotPassword }) => {

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    console.log('message:', message)

    const [findEmail, { data, loading, error:queryError }] = useLazyQuery(FIND_EMAIL, {
        onCompleted: (data) => {
          if (data.findEmail) {
            console.log('You are in the system');
            setMessage('An email has been sent with instructions on how to reset your password.');
            //we need to change the dialogue box to say that the email was sent
          } else {
            //we need to change the dialogue box to say that the email was not found
            console.log('No email found');
            setMessage('However, no account is associated with that email address.  Click below to enter a different email.');
          }
          
        },
        onError: (error) => {
          console.error('Error finding email', error);
           setMessage('An error occurred while trying to find your account. Please try again.')
        }
      });
    
      const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setError(null);
        setEmail(event.target.value);
      };
      const handleSubmit = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
          setError('Please enter a valid email address : user@example.com.');
          return;
        }
    
        setMessage(null); // Clear previous message
        findEmail({ variables: { email } });
      };
      const handleGoBack = () => {
        setMessage(null);
        setEmail('')
      }
      const handleClose=()=>{
        setMessage(null);
        setShowForgotPassword(false);
      }

  return (
    <Dialog className="relative z-10" open={showForgotPassword} onClose={setShowForgotPassword}>
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in" aria-hidden="true"></div>
      
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <Dialog.Panel
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all  data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div>
              <div className="mt-3 text-center sm:mt-5">
                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                {message ? 'Request Sent!' : 'Enter your email'}
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                  {message || "Let's see if you have an account with us! We will send an email with instructions on how to reset your password."}
                  </p>
                </div>
              </div>
            </div>
            {!message && (
              <>
                <div>
                  <label htmlFor="email" className="sr-only">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={email}
                    onChange={handleEmailChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="user@trulieve.com"
                  />
                  {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-custom-pom px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-custom-pom focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-custom-pom"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </>
            )}
            {message && (
              <div className="mt-5 sm:mt-6">
                {message === 'However, no account is associated with that email address.  Click below to enter a different email.' ? (
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-custom-pom px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-custom-pom focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={handleGoBack}
                  >
                    Go Back
                  </button>
                ) : (
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-custom-pom px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-custom-pom focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-custom-pom"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                )}
              </div>
            )}
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

export default ForgotPassword;
