import React from 'react';

interface RequiredInfoCriterionSlideOverProps {
    sambaPersonId: string;
    handleSambaPersonIdChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    mvrScore: string;
    handleMvrScoreChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    mvrState: string;
    handleMvrStateChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    mvrDate: string;
    handleMvrDateChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    correctiveAction: boolean;
    handleCorrectiveActionChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isReadOnly: boolean; 
    toggleEdit: () => void; 
}

const RequiredInfoCriterionSlideOver = ({
    sambaPersonId, handleSambaPersonIdChange, mvrScore, handleMvrScoreChange, mvrState, handleMvrStateChange, mvrDate, handleMvrDateChange, correctiveAction, handleCorrectiveActionChange, isReadOnly, toggleEdit
}: RequiredInfoCriterionSlideOverProps) => {

    return (
        <>
            <div className="">
                <div className="form-container pt-6 px-6 grid gap-1">
                    <button
                        onClick={toggleEdit}
                        className="edit-btn text-xs text-black py-1 px-4 flex flex-row-reverse"
                    >
                        {isReadOnly ? 'EDIT' : 'SAVE'}
                    </button>
                    <label htmlFor="input" className="text-sm font-medium leading-6 text-gray-900">
                        Samba Person ID
                    </label>
                    <div className="ml-7">
                        <input
                            type="input"
                            name="sambaPersonId"
                            id="sambaPersonId"
                            value={sambaPersonId}
                            onChange={handleSambaPersonIdChange}
                            className={`w-64 pl-3 rounded-md py-1.5 text-gray-900 shadow-sm ${isReadOnly ? 'read-only' : 'ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600'} sm:text-sm sm:leading-6`}
                            readOnly={isReadOnly}
                        />
                    </div>
                </div>
                <div className="form-container pt-6 px-6 grid gap-1">
                    <label htmlFor="string" className="text-sm font-medium leading-6 text-gray-900">
                        MVR Score
                    </label>
                    <div className="ml-7">
                        <input
                            type="string"
                            name="mvrScore"
                            id="mvrScore"
                            value={mvrScore}
                            onChange={handleMvrScoreChange}
                            className={`w-64 pl-3 rounded-md py-1.5 text-gray-900 shadow-sm ${isReadOnly ? 'read-only' : 'ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600'} sm:text-sm sm:leading-6`}
                            readOnly={isReadOnly}
                        />
                    </div>
                </div>
                <div className="form-container pt-6 px-6 grid gap-1">
                    <label htmlFor="state" className="text-sm font-medium leading-6 text-gray-900">
                        MVR State
                    </label>
                    <div className="ml-7">
                        <select
                            name="state"
                            id="state"
                            value={mvrState}
                            onChange={handleMvrStateChange}
                            className={`block w-64 rounded-md py-1.5 pl-2 pr-10 text-gray-900 shadow-sm ${isReadOnly ? 'read-only' : 'ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600'} sm:text-sm sm:leading-6`}
                            disabled={isReadOnly}
                        >
                            <option value="">Select a state</option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                        </select>
                    </div>
                </div>
                <div className="form-container pt-6 px-6 grid gap-1">
                    <label htmlFor="date" className="text-sm font-medium leading-6 text-gray-900">
                        MVR Date
                    </label>
                    <div className="ml-7">
                        <input
                            type="date"
                            name="mvr-date"
                            value={mvrDate}
                            onChange={handleMvrDateChange}
                            id="mvr-date"
                            className={`block pl-3 w-64 rounded-md py-1.5 text-gray-900 shadow-sm ${isReadOnly ? 'read-only' : 'ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600'} sm:text-sm sm:leading-6`}
                            readOnly={isReadOnly}
                        />
                    </div>
                </div>
                <div className="form-container pt-6 px-3 grid grid-cols-[auto_1fr] gap-2">
                    <div className="self-center justify-self-start pl-4">
                        <input
                            type="checkbox"
                            name="no-corrective-actions"
                            id="no-corrective-actions"
                            checked={correctiveAction}
                            onChange={handleCorrectiveActionChange}
                            className={`rounded text-indigo-600 focus:ring-indigo-500 ${isReadOnly ? 'read-only' : ''}`}
                            disabled={isReadOnly}
                        />
                    </div>
                    <label htmlFor="no-corrective-actions" className="text-sm font-medium leading-6 text-gray-900 self-center ml-1 justify-self-start">
                        No Corrective Actions
                    </label>
                </div>
            </div>
        </>
    )
}

export default RequiredInfoCriterionSlideOver;
