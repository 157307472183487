import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { OverdueTasksProvider } from './context/overdueTasksContext';
import { UserProvider } from './context/userContext';

import Location from './pages/locations';
import DetailLocation from './pages/detailLocation';
import MainTemplate from './components/mainTemplate/index'
import ListTemplate from './components/listTemplate/index'
// import VehiclePage from './pages/vehicle.tsx'
import EmployeePage from './pages/employee';
import EmployeeDetail from './pages/detailEmployee';
import Dashboard from './pages/dashboard'
import LoginPage from './pages/login'
import LocationAnalytics from './pages/locationAnalytics';
import DriverAnalytics from './pages/driverAnalytics';
import DashboardAnalytics from './pages/dashboardAnalytics';
import MobileDriverOnboard from './pages/mobileDriverOnboard';
import Email from './components/addEmployee'
import ProtectedRoute from './hoc/ProtectedRoute/ProtectedRoute';

function App() {
  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/location" element={<ProtectedRoute><Location /></ProtectedRoute>} />
          <Route path="/detailLocation/:id" element={<ProtectedRoute><DetailLocation /></ProtectedRoute>} />
          {/* <Route path="/mainTemplate" element={<MainTemplate />} />
          <Route path="/listTemplate" element={<ListTemplate />} /> */}
          <Route path="/employeePage" element={<ProtectedRoute><EmployeePage /></ProtectedRoute>} />
          <Route path="/employeedetail/:id" element ={<ProtectedRoute><EmployeeDetail /></ProtectedRoute>}/>
          <Route path="/" element = {<LoginPage />}/>
          <Route path ="/mobileDriverOnboard/*" element = {<ProtectedRoute><MobileDriverOnboard/></ProtectedRoute>}/>
          <Route path ="/mobileDriverOnboard" element = {<MobileDriverOnboard/>}/>
          <Route 
          path="/dashboard" 
          element={
            <ProtectedRoute>
            <OverdueTasksProvider>
              <Dashboard />
            </OverdueTasksProvider>
          </ProtectedRoute>
          } 
        />
          <Route path ="/locationAnalytics" element = {<ProtectedRoute><LocationAnalytics/></ProtectedRoute>}/>
          <Route path ="/driverAnalytics" element = {<ProtectedRoute><DriverAnalytics/></ProtectedRoute>}/>
          <Route path ="/email" element = {<Email/>}/>
          <Route 
          path="/dashboardAnalytics" 
          element={
            <ProtectedRoute>
              <OverdueTasksProvider>
                <DashboardAnalytics />
              </OverdueTasksProvider>
            </ProtectedRoute>
          } 
        />
        </Routes>
      </Router>
    </UserProvider>
  );
}

export default App;

