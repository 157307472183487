import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import '../../css/App.css';

import PageTitle from '../pageTitle/index';
import Table from '../table/index';
import FilterDropdown from '../filterDropdown';


import { DocumentChartBarIcon, ListBulletIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

import { useUser } from '../../context/userContext';

interface DropdownItem {
    label: string;
    href: string;
}

interface DropdownData {
    title: string;
    menuItems: DropdownItem[];
}

function ListTemplate ({rowDataOne, handleRowClick, pageTitle, columnDefs, dropDownData, onLocationSelected, onOperationTypeSelected, onOutlierSelection, onStateSelected, mainIcon, renderLocationOptions, currentFitForDutySelection}: any) {
    const navigate = useNavigate();

    const [isListHovered, setIsListHovered] = useState(false);
    const [isDashHovered, setIsDashHovered] = useState(false);
    const {userRole} = useUser();
    
    const renderDropdowns = () => {
        return dropDownData.map((dropdown: DropdownData, index: number) => {
          let handler;
          switch (dropdown.title) {
            case "LOCATIONS":
              handler = onLocationSelected;
              break;
            case "OPERATION TYPES":
              handler = onOperationTypeSelected;
              break;
            case "OUTLIERS":
              handler = onOutlierSelection;
              break;
            case "STATES":
              handler = onStateSelected;
              break;
            default:
              handler = undefined; 
          }
      
          return (
            <FilterDropdown
              key={index}
              buttonTitle={dropdown.title}
              menuItems={dropdown.menuItems}
              onItemSelected={handler}
            //   renderOptions={dropdown.title === "LOCATIONS" ? renderLocationOptions : undefined}
            />
          );
        });
      };

      const handleDashIconClick = () => {
        if (pageTitle === 'Locations') {
          navigate('/locationAnalytics');
        } 
        else if (pageTitle === 'Drivers') {
          navigate('/driverAnalytics');
        }
      };

    return (
    <div className="main-container">                
      <div className = "header">
          <div className="title-icons-container">
              <div className="logo-container locations-icon">
                  {mainIcon}
              </div>
              <PageTitle pageTitle={pageTitle} />
              
              <div 
                  className = "map-icon-location"
                  style ={{marginLeft: '1vw', marginTop:'2.25vh'}}    
              >
        {/* Render DocumentChartBarIcon only if the user is not a Manager or the pageTitle is not 'Locations' */}
          {(userRole !== 'Admin' && userRole !== 'Manager')  ? (
              <div
                onMouseEnter={() => setIsDashHovered(true)}
                onMouseLeave={() => setIsDashHovered(false)}
                onClick={handleDashIconClick}
              >
              {isDashHovered ? (
                <DocumentChartBarIcon className="cursor-pointer h-6 w-6 text-gray-500" />
              ) : (
                <DocumentChartBarIcon className="cursor-pointer h-6 w-6 text-gray-400" />
              )}
              </div>
          ) : null}
            <div
              onMouseEnter={() => setIsListHovered(true)} 
              onMouseLeave={() => setIsListHovered(false)}
            >
              {isListHovered ? (
                <ListBulletIcon className="cursor-pointer h-6 w-6 text-gray-400 pomegranate-glow" />
              ) : (
                <ListBulletIcon 
                    className="cursor-pointer h-6 w-6 text-gray-400" 
                    style={{ color:'#e32661' }}
                />
              )}
              </div>
            </div>
          </div>
          <div className ="filter-dropdown">
            {renderDropdowns()}
          </div>
        </div>
        <Table 
        columnDefs={columnDefs}
        rowData={rowDataOne}
        onRowClick={handleRowClick}
        />
      </div>
    )
}

export default ListTemplate;
