import '../../tailwind.css'
import './filterDropdown.css';

import { Fragment, ReactNode } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
  }

  interface MenuItem {
    label: string;
    href: string;
  }
  
  interface FilterDropdownProps {
    buttonTitle: string;
    menuItems: MenuItem[];
    onItemSelected?: (selectedItem: string) => void;
    renderOptions?: () => ReactNode;
  }

  const FilterDropdown = ({ buttonTitle, menuItems, onItemSelected, renderOptions } : FilterDropdownProps) => {
    
    const handleMenuItemClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, itemLabel: string) => {
      event.preventDefault(); 

      console.log("FilterDropdown Item Clicked:", itemLabel);
      if (onItemSelected) {
          onItemSelected(itemLabel);
      }
  };


    return (
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            {buttonTitle}
            <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true"/>
          </Menu.Button>
        </div>
  
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >

          <Menu.Items 
          style={{ minWidth: '200px', width: 'auto', right: '0', zIndex: 50}} 
          className="dropdown-menu absolute right-0 z-10 mt-2 w-56 min-w-max origin-top-right rounded-md bg-white shadow-lg border border-gray-300 ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {menuItems.map((item, index) => (
              <Menu.Item key={index} >
                {({ active }) => (
                  <a 
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm no-underline hover:bg-gray-100'
                    )}
                    onClick={(e) => handleMenuItemClick(e, item.label)}
                  >
                    {item.label}
                  </a>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
        </Transition>
      </Menu>
    );
  };
  
  export default FilterDropdown;
