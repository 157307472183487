interface ValidationResult {
    isValid: boolean;
    errors: string[];
  }
  
  const ZipValidator = (zip: string): ValidationResult => {
    const errors: string[] = [];
  
    // 1. Required Field Validation
    if (!zip) {
      errors.push("This field is required.");
    }
  
    // 2. Numeric Characters Only (allows optional hyphen for ZIP+4)
    if (!/^\d{5}(-\d{4})?$/.test(zip)) {
      errors.push("ZIP code must be 5 digits or ZIP+4 format (XXXXX-XXXX).");
    }
  
    // 3. Valid Length (5 or 9 digits)
    const zipDigitsOnly = zip.replace("-", ""); // Remove hyphen if present
    if (zipDigitsOnly.length !== 5 && zipDigitsOnly.length !== 9) {
      errors.push("ZIP code must be 5 or 9 digits long.");
    }
  
    return {
      isValid: errors.length === 0,
      errors,
    };
  };
  
  export default ZipValidator;
  